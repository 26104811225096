import React from 'react'
import {Card, Button, Container} from 'react-bootstrap'

export default function Cards() {
  return (

    <Container className="d-flex ">
       <Card style={{ width: '18rem' }} className='m-3'>
  <Card.Img variant="top" src="/images/1.png" />
  <Card.Body>
    <Card.Title>Card Title</Card.Title>
    <Card.Text>
      Some quick example text to build on the card title and make up the bulk of
      the card's content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
  </Card.Body>
</Card>
       <Card style={{ width: '18rem' }} className='m-3'>
  <Card.Img variant="top" src="/images/1.png" />
  <Card.Body>
    <Card.Title>Card Title</Card.Title>
    <Card.Text>
      Some quick example text to build on the card title and make up the bulk of
      the card's content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
  </Card.Body>
</Card>
       <Card style={{ width: '18rem' }} className='m-3'>
  <Card.Img variant="top" src="/images/1.png" />
  <Card.Body>
    <Card.Title>Card Title</Card.Title>
    <Card.Text>
      Some quick example text to build on the card title and make up the bulk of
      the card's content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
  </Card.Body>
</Card>
       <Card style={{ width: '18rem' }} className='m-3'>
  <Card.Img variant="top" src="/images/1.png" />
  <Card.Body>
    <Card.Title>Card Title</Card.Title>
    <Card.Text>
      Some quick example text to build on the card title and make up the bulk of
      the card's content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
  </Card.Body>
</Card>

    </Container>
  
  ) 
}
