import React from "react";
import { Figure, Container } from "react-bootstrap";

export default function Fig() {
  return (
    <Container className="mt-6 p-4">
      <Figure className="m-4">
        <Figure.Image
          width={171}
          height={180}
          alt="171x180"
          src="/images/fig-1.png"
        />
        <Figure.Caption>This is most expensive</Figure.Caption>
      </Figure>
      <Figure className="m-4">
        <Figure.Image
          width={171}
          height={180}
          alt="171x180"
          src="/images/fig-1.png"
        />
        <Figure.Caption>This is most expensive</Figure.Caption>
      </Figure>
      <Figure className="m-4">
        <Figure.Image
          width={171}
          height={180}
          alt="171x180"
          src="/images/fig-1.png"
        />
        <Figure.Caption>This is most expensive</Figure.Caption>
      </Figure>
      <Figure className="m-4">
        <Figure.Image
          width={171}
          height={180}
          alt="171x180"
          src="/images/fig-1.png"
        />
        <Figure.Caption>This is most expensive</Figure.Caption>
      </Figure>
      <Figure className="m-4">
        <Figure.Image
          width={171}
          height={180}
          alt="171x180"
          src="/images/fig-1.png"
        />
        <Figure.Caption>This is most expensive</Figure.Caption>
      </Figure>
    </Container>
  );
}
