import React from "react";
import Accords from "../components/Accords";

export default function Features() {
  return (
    <div>
      <Accords />
    </div>
  );
}
