import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand Link to="/">
          Catpie
        </Navbar.Brand>
        <Nav className="me-auto">
          <Link className="nav-link" to="/">
            Home
          </Link>
          <Link className="nav-link" to="/features">
            Features
          </Link>
          <Link className="nav-link" to="/pricing">
            Pricing
          </Link>
          {/* <Link className="nav-link" to="/youtube">
            Youtube
          </Link> */}
          <Link className="nav-link" to="/contact">
            Contact US
          </Link>
        </Nav>
      </Container>
    </Navbar>
  );
}
