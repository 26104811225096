import React from 'react'
import { Carousel, Item, Caption, Container } from 'react-bootstrap'
import ImgSlider from '../components/ImgSlider'
import Cards from '../components/Cards'

export default function Home() {
  return (
    <>
        <Container>
            <ImgSlider/>
            <Cards/>
        </Container>
    
    
    </>
  )
}
