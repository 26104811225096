import React from "react";
import Fig from "../components/Fig";

export default function Pricing() {
  return (
    <div>
      <Fig />
    </div>
  );
}
